import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"ma-3",attrs:{"wrap":""}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"descricao",attrs:{"label":"Descrição","placeholder":"Ex.: Competência 01.","outlined":"","dense":"","data-vv-as":"descrição","persistent-hint":"","hint":_vm.errors.first('avd-competencia.descricao'),"error":_vm.errors.collect('avd-competencia.descricao').length ? true : false,"hide-details":_vm.errors.collect('avd-competencia.descricao').length ? false : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"avd-competencia","id":"descricao","name":"descricao"},model:{value:(_vm.competencia.descricao),callback:function ($$v) {_vm.$set(_vm.competencia, "descricao", $$v)},expression:"competencia.descricao"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c(VSelect,{attrs:{"label":"Nivel","items":_vm.niveis,"item-text":"descricao","item-value":"id","placeholder":"Selecione um nível","outlined":"","dense":"","no-data-text":"Nenhum nível selecionado","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(item.descricao)))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(item.descricao)))]):_vm._e()]}}]),model:{value:(_vm.competencia.id_nivel),callback:function ($$v) {_vm.$set(_vm.competencia, "id_nivel", $$v)},expression:"competencia.id_nivel"}})],1),_c(VCol,{staticClass:"my-3",attrs:{"cols":"12"}},[_c(VFadeTransition,[(!_vm.competencia.peso)?_c(VAlert,{attrs:{"dense":"","text":"","color":"warning","icon":"mdi-alert-circle","type":"info"}},[_vm._v(" O valor "),_c('strong',[_vm._v("0")]),_vm._v(", por padrão, representa uma competência sem peso. ")]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row align-center mx-0 mx-md-6"},[_c(VSlider,{attrs:{"label":"Peso","thumb-label":"","hide-details":"","min":"0","max":"4","ticks":"always","tick-size":"5","color":"primary"},model:{value:(_vm.competencia.peso),callback:function ($$v) {_vm.$set(_vm.competencia, "peso", $$v)},expression:"competencia.peso"}}),_c('span',{staticClass:"text-subtitle-2 font-weight-bold"},[_vm._v(_vm._s(_vm.competencia.peso))])],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ComportamentosComponent')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }