import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Criar nível ")]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:100'),expression:"'required|min:3|max:100'"}],attrs:{"label":"Nome","placeholder":"Ex.: Nível 01.","outlined":"","dense":"","required":"","data-vv-as":"nome","data-vv-name":"descricao","persistent-hint":"","hint":_vm.errors.first('avd-nivel.descricao'),"error":_vm.errors.collect('avd-nivel.descricao').length ? true : false,"hide-details":_vm.errors.collect('avd-nivel.descricao').length ? false : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"avd-nivel"},model:{value:(_vm.nivel.descricao),callback:function ($$v) {_vm.$set(_vm.nivel, "descricao", $$v)},expression:"nivel.descricao"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar ")]),_c(VBtn,{attrs:{"loading":_vm.loadingButtonSave,"color":"success"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }