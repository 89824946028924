import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_vm._m(0),_c('div',{staticClass:"d-flex align-end justify-end"},[_c(VBtn,{attrs:{"small":"","color":"accent"},on:{"click":function($event){return _vm.newComportamento()}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" criar comportamento ")],1)],1),_c('div',{staticClass:"d-flex flex-column justify-center mt-7"},[_c(VFadeTransition,{attrs:{"group":""}},_vm._l((_vm.competencia.comportamentos),function(item,i){return _c(VCard,{key:i,staticClass:"my-2",attrs:{"elevation":"0"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"my-2 pa-0",attrs:{"cols":"11"}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row align-sm-center align-end"},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"Descrição","placeholder":"Ex.: Comunica-se de forma educada.","outlined":"","dense":"","data-vv-as":"descrição","data-vv-name":("descricao-comportamento-" + i),"persistent-hint":"","hint":_vm.errors.first(("avd-competencia.descricao-comportamento-" + i)),"error":_vm.errors.collect(
                    ("avd-competencia.descricao-comportamento-" + i)
                  ).length
                    ? true
                    : false,"hide-details":"","data-vv-validate-on":"change|blur|input","data-vv-scope":"avd-competencia"},model:{value:(item.descricao),callback:function ($$v) {_vm.$set(item, "descricao", $$v)},expression:"item.descricao"}}),_c(VBtn,{staticClass:"ml-0 ml-sm-2 pa-3",attrs:{"loading":_vm.loadingDelete,"color":"error"},on:{"click":function($event){return _vm.remove(item, i)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)])],1)],1)}),1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-start justify-start"},[_c('h2',{staticClass:"text-h6"},[_vm._v("Comportamentos")])])}]

export { render, staticRenderFns }