<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- descricao -->
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Descrição"
          ref="descricao"
          placeholder="Ex.: Competência 01."
          outlined
          dense
          v-model="competencia.descricao"
          data-vv-as="descrição"
          persistent-hint
          :hint="errors.first('avd-competencia.descricao')"
          :error="
            errors.collect('avd-competencia.descricao').length ? true : false
          "
          :hide-details="
            errors.collect('avd-competencia.descricao').length ? false : true
          "
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-competencia"
          id="descricao"
          name="descricao"
        ></v-text-field>
      </v-col>
      <!-- Escalas -->
      <!-- <v-col cols="12" sm="12" md="6" lg="6">
        <v-autocomplete
          label="Tipos Escalas"
          :items="listaTiposEscalas"
          item-value="sigla"
          item-text="name"
          dense
          outlined      
          v-model="competencia.id_escala"
          required
          no-data-text="Nenhum dado disponível"
          :hint="errors.first('avd-competencia.id_escala')"
          :error="
            errors.collect('avd-competencia.id_escala').length ? true : false
          "
          :hide-details="
            errors.collect('avd-competencia.id_escala').length ? false : true
          "
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-competencia"
        >
        </v-autocomplete>
      </v-col> -->
      <!-- Nivel -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Nivel"
          :items="niveis"
          item-text="descricao"
          item-value="id"
          placeholder="Selecione um nível"
          outlined
          dense
          v-model="competencia.id_nivel"
          no-data-text="Nenhum nível selecionado"
          clearable
          hide-details
        >
          <template v-slot:selection="{ item }">
            <span v-if="item">{{ item.descricao | capitalize() }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span v-if="item">{{ item.descricao | capitalize() }}</span>
          </template>
        </v-select>
        <!-- v-btn @click="dialog = true" text small color="accent" class="px-2">
          <v-icon small>mdi-plus</v-icon> criar um nível
        </v-btn> -->
      </v-col>
      <!-- <v-col cols="12" sm="12" md="6" lg="6">
        <v-autocomplete
          label="Cargos"
          :items="cargos"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um ou vários cargos"
          outlined
          dense
          multiple
          required
          chips
          deletable-chips
          v-model="competencia.ids_cargos"
          :loading="loadings.loadingCargo"
          :disabled="loadings.loadingCargo"
          no-data-text="Nenhum cargo selecionado"
          data-vv-as="cargos"
          persistent-hint
          :hint="errors.first('avd-competencia.cargo')"
          :error="errors.collect('avd-competencia.cargo').length ? true : false"
          :hide-details="
            errors.collect('avd-competencia.cargo').length ? false : true
          "
          v-validate="'required'"
          data-vv-validate-on="change|blur|input"
          data-vv-scope="avd-competencia"
          id="cargo"
          name="cargo"
        ></v-autocomplete>
      </v-col> -->
      <!-- Setor -->
      <!-- <v-col cols="12" sm="12" md="6" lg="6">
        <v-autocomplete
          label="Setores"
          :items="setores"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um ou vários setores"
          outlined
          dense
          multiple
          required
          chips
          deletable-chips
          v-model="competencia.ids_setores"
          :loading="loadings.loadingSetor"
          :disabled="loadings.loadingSetor"
          no-data-text="Nenhum setor selecionado"
          hide-details
        ></v-autocomplete>
      </v-col> -->
      <!-- Peso -->
      <v-col cols="12" class="my-3">
        <v-fade-transition>
          <v-alert
            v-if="!competencia.peso"
            dense
            text
            color="warning"
            icon="mdi-alert-circle"
            type="info"
          >
            O valor <strong>0</strong>, por padrão, representa uma competência
            sem peso.
          </v-alert>
        </v-fade-transition>
        <div class="d-flex flex-row align-center mx-0 mx-md-6">
          <v-slider
            label="Peso"
            v-model="competencia.peso"
            thumb-label
            hide-details
            min="0"
            max="4"
            ticks="always"
            tick-size="5"
            color="primary"
          >
          </v-slider>
          <span class="text-subtitle-2 font-weight-bold">{{
            competencia.peso
          }}</span>
        </div>
      </v-col>
      <v-col cols="12">
        <ComportamentosComponent />
      </v-col>
    </v-row>
    <!-- <ModalNivelComponent :dialog="dialog" @close-dialog="dialog = false" /> -->
  </v-container>
</template>

<script>
import ModalNivelComponent from "./components/competencia/ModalNivelComponent.vue";
import ComportamentosComponent from "./components/competencia/ComportamentosComponent.vue";
import store from "@/store";
import { ref, computed } from "@vue/composition-api";
export default {
  name: "CompetenciaForm",
  inject: ["$validator"],
  components: {
    ModalNivelComponent,
    ComportamentosComponent,
  },
  setup(_, { emit }) {
    // Variables
    const usingPeso = ref(false);

    // Computed
    const competencia = computed(
      () => store.getters["avdCompetencias/getCompetencia"]
    );
    const niveis = computed(() => store.getters["avdNiveis/getNiveis"]);

    // Function
    function validatePeso() {
      if (competencia.value.peso == null) return false;
      else return true;
    }

    // const listaTiposEscalas = [
    //   { sigla: "CC", name: "Concordo...Discordo" },
    // ];
    return {
      // Variables
      usingPeso,

      // Computed
      competencia,
      niveis,
      // listaTiposEscalas,

      //Functions
      validatePeso,
    };
  },
};
</script>

<style></style>
