<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-start justify-start">
      <h2 class="text-h6">Comportamentos</h2>
    </div>
    <div class="d-flex align-end justify-end">
      <v-btn small @click="newComportamento()" color="accent">
        <v-icon>mdi-plus</v-icon> criar comportamento
      </v-btn>
    </div>
    <div class="d-flex flex-column justify-center mt-7">
      <v-fade-transition group>
        <v-card
          elevation="0"
          class="my-2"
          v-for="(item, i) in competencia.comportamentos"
          :key="i"
        >
          <v-row no-gutters>
            <v-col class="my-2 pa-0" cols="11">
              <div class="d-flex flex-column flex-sm-row align-sm-center align-end">
                <v-text-field
                  label="Descrição"
                  placeholder="Ex.: Comunica-se de forma educada."
                  outlined
                  dense
                  v-model="item.descricao"
                  data-vv-as="descrição"
                  :data-vv-name="`descricao-comportamento-${i}`"
                  persistent-hint
                  :hint="
                    errors.first(`avd-competencia.descricao-comportamento-${i}`)
                  "
                  :error="
                    errors.collect(
                      `avd-competencia.descricao-comportamento-${i}`
                    ).length
                      ? true
                      : false
                  "
                  hide-details
                  v-validate="'required'"
                  data-vv-validate-on="change|blur|input"
                  data-vv-scope="avd-competencia"
                >
                </v-text-field>
                <v-btn
                  @click="remove(item, i)"
                  :loading="loadingDelete"
                  class="ml-0 ml-sm-2 pa-3"
                  color="error"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComportamentosComponent",
  inject: ["$validator"],
  data() {
    return {
      loadingDelete: false,
    };
  },
  computed: {
    competencia: {
      get() {
        return this.$store.getters["avdCompetencias/getCompetencia"];
      },
      set(value) {
        this.$store.dispatch("avdCompetencias/setCompetencia", value);
      },
    },
  },
  methods: {
    newComportamento() {
      const comportamento = {
        descricao: "",
      };

      this.competencia.comportamentos.push(comportamento);
    },
    remove(item = Object, positionInArray = Number) {
      if (this.competencia.comportamentos.length == 1 && positionInArray == 0) {
        this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
          visibilidade: true,
          mensagem:
            "A competência deve ter ao menos um comportamento registrado!",
          cor: "red",
        });

        return;
      }

      if (item.id != undefined || item.id != null) {
        this.loadingDelete = true;

        this.$store
          .dispatch("avdCompetencias/removeComportamento", item)
          .then((response) => {
            this.competencia.comportamentos.splice(positionInArray, 1);
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao deletar o comportamento. Por favor, tente novamente mais tarde!",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingDelete = false;
          });
      } else {
        this.competencia.comportamentos.splice(positionInArray, 1);
      }
    },
  },
};
</script>

<style></style>
