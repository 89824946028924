<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="text-h5"> Criar nível </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              label="Nome"
              placeholder="Ex.: Nível 01."
              outlined
              dense
              required
              v-model="nivel.descricao"
              data-vv-as="nome"
              data-vv-name="descricao"
              persistent-hint
              :hint="errors.first('avd-nivel.descricao')"
              :error="
                errors.collect('avd-nivel.descricao').length ? true : false
              "
              :hide-details="
                errors.collect('avd-nivel.descricao').length ? false : true
              "
              v-validate="'required|min:3|max:100'"
              data-vv-validate-on="change|blur|input"
              data-vv-scope="avd-nivel"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close()">Cancelar </v-btn>
        <v-btn :loading="loadingButtonSave" color="success" @click="save()">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalNivelComponent",
  inject: ["$validator"],
  props: {
    dialog: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      loadingButtonSave: false,
    };
  },
  computed: {
    nivel: {
      get() {
        return this.$store.getters["avdNiveis/getNivel"];
      },
      set(value) {
        this.$store.dispatch("avdNiveis/setNivel", value);
      },
    },
    niveis: {
      get() {
        return this.$store.getters["avdNiveis/getNiveis"];
      },
    },
  },
  methods: {
    save() {
      this.$validator.validate("avd-nivel.*").then((result) => {
        if (result) {
          this.loadingButtonSave = true;

          const item = this.nivel;
          item.url = "/avd/nivel";
          item._method = "POST";

          this.$store
            .dispatch("avdNiveis/save", item)
            .then((response) => {
              this.niveis.push(response);

              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: "Nível cadastrado com sucesso.",
                cor: "green",
              });
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem:
                  "Houve um erro ao cadastrar o nível. Por favor, tente novamente mais tarde.",
                cor: "red",
              });
            })
            .finally(() => {
              this.loadingButtonSave = false;
              this.close();
            });
        }
      });
    },
    close() {
      this.nivel = {
        descricao: "",
      };
      this.$emit("close-dialog");
    },
  },
};
</script>

<style></style>
